export default {
  namespaced: true,
  name: 'purchases',

  translatedAttributes: [
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    ...item,
    // ...
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    id: data.id,
    items: data.items,
    payer: data.payer,
    payment_methods: data.payment_methods,
    coupon_code: data.coupon_code,
    payment_method_code: data.payment_method_code,
    payment_method: data.payment_method,
    currency_code: data.currency_code,
    summary: data.summary,
    status: data.status,
    paid_at: data.paid_at,
  }),

  resolveValidationErrors: errors => ({
    ...errors,
  }),
}
