import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import themeModel from '@/views/models/settings/layout-settings/themeModel'

const {
  fetchAll,
  // fetchOne,
  create,
} = useModelStoreModule(themeModel, true)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    // fetchOne,
    create,
  },
}
