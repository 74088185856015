<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div
      class="bg-light-primary rounded-top text-center"
      style="height: 200px; overflow: hidden"
    >
      <b-img
        :src="require('@/assets/images/pages/layout-builder.svg')"
        alt="Meeting Pic"
        height="250"
      />
    </div>

    <b-progress
      v-if="loading"
      animated
      :value="100"
      variant="primary"
      dir="ltr"
      class="progress-bar-primary"
    />
    <b-card-body>
      <b-row>
        <b-col
          sm="8"
          xs="12"
        >
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="50"
              >
                <feather-icon
                  :icon="theme.icon || 'SquareIcon'"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <div class="my-auto">
                <b-card-title class="mb-25">
                  {{ $t('modules.layout_settings._list.current_theme') }}: {{ theme.name }}
                </b-card-title>
                <b-card-text class="mb-0">
                  {{ $t('modules.layout_settings._list.version') }} {{ theme.version }}
                </b-card-text>
              </div>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          sm="4"
          xs="12"
        >
          <b-button
            variant="primary"
            block
            :to="{name: 'themeEditor', params: {theme: theme.code}}"
            :disabled="loading"
          >
            <template v-if="loading">
              <b-spinner
                small
                class="mr-50"
              />
              <span v-t="`modules.layout_settings._messages.installation_in_progress`" />
            </template>
            <template v-else>
              <material-icon
                icon="draw"
                class="mr-50"
              />
              <span v-t="`modules.layout_settings._list.customize`" />
            </template>
          </b-button>

          <small
            v-if="loading"
            v-t="`modules.layout_settings._messages.installation_in_progress_note`"
            class="d-block text-center"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  VBTooltip,
  BMediaBody,
  BButton, BCol, BRow, BSpinner, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BProgress,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
