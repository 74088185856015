export default {
  name: 'themes',

  translatedAttributes: [],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => item,

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    ...data,
  }),

  resolveValidationErrors: errors => ({
    ...errors,
  }),
}
