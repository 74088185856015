<template>
  <div>
    <layout-settings-loader
      v-if="isLoading"
    />
    <template v-else>
      <installed-themes-card
        v-if="currentTheme"
        :theme="currentTheme"
        :loading="!$store.state.storeConfig.isThemeReady"
      />

      <template v-if="themeStyles.length">
        <hr>
        <p
          v-t="`modules.layout_settings._list.use_another_theme_message`"
          class="pt-1"
        />
        <div class="grid-view">
          <theme-card
            v-for="themeStyle in themeStyles"
            :key="`suggestion_${themeStyle.code}`"
            :theme="themeStyle"
            @click-purchase="purchaseTheme"
            @click-apply="theme => applyTheme(theme)"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue, {
  onBeforeMount, onMounted, onUnmounted, ref,
} from 'vue'
import store from '@/store'
import InstalledThemesCard from '@/views/models/settings/layout-settings/InstalledThemesCard.vue'
import ThemeCard from '@/views/models/settings/layout-settings/ThemeCard.vue'
import themeStoreModule from '@/views/models/settings/layout-settings/themeStoreModule'
import { usePurchaseCart } from '@/views/models/purchases/usePurchaseCart'
import {handleBadRequestError, handleValidationError, isDevelopment, toast} from '@core/utils/utils'
import Router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import LayoutSettingsLoader from '@/views/loaders/LayoutSettingsLoader.vue'

export default {
  components: {
    LayoutSettingsLoader,
    ThemeCard,
    InstalledThemesCard,
  },
  emits: ['update:settings'],
  props: {
    settings: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      // suggestions: [
      //   {
      //     code: 'next',
      //     name: 'Next',
      //     description: 'Next theme customized for fashion stores.',
      //     brand: 'Prmga',
      //     price: 20,
      //     image: require('@/assets/images/pages/eCommerce/27.png'),
      //     url: 'https://fashion.kssib.com/',
      //     rating: 5,
      //   },
      // ],
    }
  },
  beforeCreate() {

  },
  methods: {
    handleTabChangedEvent() {
      // this.$nextTick(() => {
      //   // TODO: Subpage breadcrumb
      //   const selectedTabIndex = this.$refs.tabs.currentTab
      //   document.getElementById('appBreadcrumb')
      //     .getElementsByClassName('active')[0]
      //     .textContent = this.$refs.tabs.$refs.nav.children[selectedTabIndex].textContent
      // })
    },
  },
  setup(props, ctx) {
    const THEME_STORE_MODULE_NAME = 'themes'

    // Register module
    if (!store.hasModule(THEME_STORE_MODULE_NAME)) store.registerModule(THEME_STORE_MODULE_NAME, themeStoreModule)
    onMounted(() => {
      if (!store.hasModule(THEME_STORE_MODULE_NAME)) store.registerModule(THEME_STORE_MODULE_NAME, themeStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(THEME_STORE_MODULE_NAME)) store.unregisterModule(THEME_STORE_MODULE_NAME)
    })

    onBeforeMount(() => {
      //
    })

    const currentTheme = ref(null)
    const themeStyles = ref([])
    const isLoading = ref(true)
    const { t } = useI18nUtils()

    const {
      updateProductQty,
    } = usePurchaseCart()

    const purchaseTheme = purchasableCode => {
      isLoading.value = true
      updateProductQty(purchasableCode, 1).then(response => {
        if (isDevelopment()) {
          console.log('CONFIRMATION', response)
        }
        Router.push({ name: 'purchases.checkout' })
      }).catch(e => {
        // toast('danger', 'Error', e.response?.data?.message || e.message)

        const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
        toast('danger', title, '', 'AlertTriangleIcon')
      }).finally(() => {
        isLoading.value = false
      })
    }

    const applyTheme = theme => {
      Vue.swal({
        title: t('Are you sure?'),
        text: t('modules.layout_settings._messages.apply_theme_confirmation_message'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('Cancel'),
        confirmButtonText: `${t('modules.layout_settings._messages.apply_theme_ok_btn_label')}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          isLoading.value = true
          store.dispatch(`${THEME_STORE_MODULE_NAME}/create`, {
            style_code: theme,
          })
            .then(response => {
              window.location.reload()
            })
            .catch(e => {
              if (!handleValidationError(e) && !handleBadRequestError(e)) {
                const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
                toast('danger', title, '', 'AlertTriangleIcon')
              }
            }).finally(() => {
              isLoading.value = false
            })
        }
      }).finally(() => {
        isLoading.value = false
      })
    }

    store.dispatch(`${THEME_STORE_MODULE_NAME}/fetchAll`)
      .then(response => {
        currentTheme.value = response.data.current
        themeStyles.value = response.data.data

        store.commit('storeConfig/UPDATE_THEME_READY', currentTheme.value.ready)
      })
      .catch(() => {
        const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
        toast('danger', title, '', 'AlertTriangleIcon')
      }).finally(() => {
        isLoading.value = false
      })

    return {
      isLoading,
      currentTheme,
      themeStyles,
      purchaseTheme,
      applyTheme,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
