<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center">
      <b-link
        :href="theme.preview_url"
        target="_blank"
        class="w-100"
      >
        <div
          class="card-img-top theme-preview-image"
          :style="`background-image: url('${theme.preview_img}');`"
        />
      </b-link>
    </div>

    <!-- Theme Details -->
    <b-card-body>
      <div class="item-wrapper">
        <div
          v-if="theme.rating"
          class="item-rating"
        >
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{'ml-25': star-1}"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[{'fill-current': star <= theme.rating}, star <= theme.rating ? 'text-warning' : 'text-muted']"
              />
            </li>
          </ul>
        </div>
        <div>
          <h6
            v-if="theme.price.amount"
            class="item-price"
          >
            <del v-if="theme.sale_price">{{ theme.price.string }}</del>
            {{ (theme.sale_price || theme.price).string }}
          </h6>
          <span
            v-else
            v-t="'Free'"
            class="item-price pricing-basic-value font-weight-bolder text-primary"
          />
        </div>
        <div v-if="theme.tags && theme.tags.length">
          <b-badge
            v-for="tag in theme.tags"
            :key="`tag-${tag}`"
            v-t="tag"
            :variant="'light-warning'"
            class="mr-1 ml-auto"
          />
        </div>
      </div>
      <h6 class="item-name">
        <b-link
          class="text-body"
          :href="theme.preview_url"
          target="_blank"
        >
          {{ theme.name }}
        </b-link>
        <b-card-text class="item-company">
          By <b-link class="ml-25">
            {{ theme.brand }}
          </b-link>
        </b-card-text>
      </h6>
      <b-card-text class="item-description">
        {{ theme.description }}
      </b-card-text>
    </b-card-body>

    <!-- Theme Actions -->
    <div class="item-options text-center">
      <div class="item-wrapper">
        <div class="item-cost">
          <h4
            v-if="theme.price.amount"
            class="item-price"
          >
            <del v-if="theme.sale_price">{{ theme.price.string }}</del>
            {{ (theme.sale_price || theme.price).string }}
          </h4>
          <span
            v-else
            v-t="'Free'"
            class="item-price pricing-basic-value font-weight-bolder text-primary"
          />
        </div>
      </div>
      <b-button
        variant="light"
        tag="a"
        class="btn-wishlist"
        :href="theme.preview_url"
        target="_blank"
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-50"
        />
        <span v-t="`modules.layout_settings._list.preview_btn_label`" />
      </b-button>
      <b-button
        v-if="theme.purchasable_code"
        variant="primary"
        tag="a"
        class="btn-cart"
        @click="$emit('click-purchase', theme.purchasable_code)"
      >
        <feather-icon
          icon="ShoppingCartIcon"
          class="mr-50"
        />
        <span v-t="`modules.layout_settings._list.purchase_btn_label`" />
      </b-button>
      <b-button
        v-else
        variant="primary"
        tag="a"
        class="btn-cart"
        @click="$emit('click-apply', theme.code)"
      >
        <material-icon
          icon="format_paint"
          class="mr-50"
          size="20"
        />
        <span v-t="`modules.layout_settings._list.use_btn_label`" />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  VBTooltip,
  BButton, BLink, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BLink,
    BButton,
    BCard,
    BCardBody,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  emits: ['click-purchase', 'click-apply'],
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="css">
.theme-preview-image {
  width: 100%;
  height: 230px;
  background-size: cover;
  background-position: top;
  transition: background-position ease-in-out 0.5s;
}
.theme-preview-image:hover {
  background-position: bottom;
  transition-duration: 5s;
}
</style>
