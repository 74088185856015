import store from '@/store'
import purchaseModel from '@/views/models/purchases/purchaseModel'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import { onMounted, onUnmounted } from 'vue'

const PURCHASES_STORE_MODULE_NAME = 'purchases'

export const usePurchaseCart = () => {
  if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
  onMounted(() => {
    if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
  })

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.unregisterModule(PURCHASES_STORE_MODULE_NAME)
  })

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch(`${purchaseModel.name}/update`, { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const updateProductQty = (productId, productQty) => {
    return store.dispatch(`${purchaseModel.name}/updateAttribute`, {
      itemId: 'cart',
      attribute: 'items',
      value: [{
        id: productId,
        qty: productQty,
      }],
    })
  }

  // eslint-disable-next-line arrow-body-style
  const applyCoupon = couponCode => {
    console.log('DEBUG', {
      itemId: 'cart',
      attribute: 'coupon_code',
      value: couponCode,
    })
    return store.dispatch(`${purchaseModel.name}/updateAttribute`, {
      itemId: 'cart',
      attribute: 'coupon_code',
      value: couponCode,
    })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => updateProductQty(productId, 0)

  // eslint-disable-next-line arrow-body-style
  const checkoutPurchase = (paymentMethodCode, paymentData) => {
    return store.dispatch(`${purchaseModel.name}/create`, {
      payment_method_code: paymentMethodCode,
      payment_data: paymentData,
    })
  }

  return {
    checkoutPurchase,
    addProductInCart,
    updateProductQty,
    removeProductFromCart,
    applyCoupon,
  }
}
