import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import purchaseModel from './purchaseModel'

const {
  fetchAll,
  fetchOne,
  createWithMeta,
  update,
  updateAttribute,
} = useModelStoreModule(purchaseModel, true)

export default {
  name: 'purchases',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    create: createWithMeta,
    update,
    updateAttribute,
  },
}
